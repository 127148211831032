<template>
  <div id="register">
    <el-form>
      <h1>注册账户</h1>
      <!-- 手机号 -->
      <el-input
        v-model="form.userPhone"
        placeholder="请输入手机号"
        prefix-icon="el-icon-phone"
        clearable
      ></el-input>
      <!-- 密码 -->
      <el-input
        v-model="form.userPassword"
        placeholder="请输入密码"
        show-password
        prefix-icon="el-icon-lock"
        @keydown.enter="register()"
      ></el-input>
      <!-- 用户名 -->
      <el-input
        v-model="form.userName"
        placeholder="请输入用户名"
        prefix-icon="el-icon-user"
        clearable
      ></el-input>
      <el-button type="primary" style="width: 100%" @click="register()"
        >注 册</el-button
      >
    </el-form>
    <div class="login" @click="linkLogin">登录账户</div>
  </div>
</template>

<script>
import { userRegister } from "@/api/setting/userlist";
export default {
  data() {
    return {
      form: {
        userName: "", //用户名
        userPhone: "", //手机号
        userPassword: "", //密码
      },
    };
  },
  methods: {
    // 注册账户
    register: _.throttle(function () {
      let data = this.form;
      userRegister(data).then((res) => {
        if (res.success) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.$router.push({
            path: "/mine/login",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    }, 1000),

    // 跳转登录
    linkLogin: _.throttle(function () {
      this.$router.push({
        path: "/mine/login",
      });
    }, 1000),
  },
};
</script>


<style lang="scss" scoped>
#register {
  width: 3rem;
  height: 3rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  box-sizing: border-box;
}

h1 {
  font-size: 0.2rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
  letter-spacing: 0.05rem;
}

.el-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login {
  margin: 0.1rem 0;
  text-align: end;
  color: #409eff;
  cursor: pointer;
  font-size: 0.16rem;
}
</style>